import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ScHeroContainer = styled(motion.div)`
	@media (min-height: 500px) and (min-width: 390px) {
		height: calc(100svh - 85px);
	}
	@media (min-width: 768px) {
		height: 100%;
		@media (min-height: 1000px) {
			height: calc(100svh - 105px);
		}
	}
	@media (min-width: 1024px) {
		height: 100%;
		@media (min-height: 600px) {
			height: calc(100svh - 105px);
		}
	}
	@media (min-width: 1220px) {
		height: 100%;
		@media (min-height: 600px) {
			height: calc(100vh - 140px);
		}
	}
`;

export const ScEmployeeCyclingCalculatorContainer = styled.div`
	display: flex;
	flex-direction: column;
	svg {
		width: 100vw;
		height: auto;
	}
`;

export const ScEmployeeCyclingHeroContainer = styled.div`
	margin-bottom: 40px;
	@media (min-width: 768px) {
		margin-bottom: 100px;
	}
	@media (min-width: 1220px) {
		margin-bottom: 200px;
	}
`;

export const ScEmployeeCyclingCalculatorWrapper = styled.div`
	background: ${({ theme }) => theme.colors.white};
	section {
		width: 100%;
	}
	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 26px;
		padding: 40px 0;
		@media (min-width: 1220px) {
			gap: 80px;
			padding: 40px 0 50px 0;
		}
		@media (min-width: 1920px) {
			gap: 100px;
		}
	}
`;

export const ScEmployeeCyclingHeroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	height: 100%;
	@media (min-width: 1024px) {
		gap: 20px;
		display: grid;
		grid-template-columns: 105fr 75fr;
		height: 100%;
	}
`;

export const ScEmployeeCyclingHeroLeft = styled(motion.div)`
	padding: 20px;
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.marmelade};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	height: 100%;
	img {
		width: 100%;
		height: auto;
	}
	@media (min-width: 1220px) {
		padding: 95px 25px;
		border-radius: 30px;
	}
	@media (min-width: 1220px) and (max-width: 1919px) and (max-height: 900px) {
		padding: 45px 25px;
		img {
			width: 80%;
		}
	}
`;

export const ScEmployeeCyclingHeroRight = styled(motion.div)`
	padding: 25px 45px;
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.peach};
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	border-radius: 10px;
	width: 100%;
	button {
		margin-top: 10px;
	}
	h1 {
		color: ${({ theme }) => theme.colors.darkText};
		text-align: center;
		margin: 0;
		font: ${({ theme }) => theme.fonts.heading.small};
		@media (min-width: 768px) {
			font: ${({ theme }) => theme.fonts.hero.medium};
		}
		@media (min-width: 1220px) {
			text-align: left;
			line-height: 80px;
		}
		@media (min-width: 1220px) and (max-width: 1919px) and (max-height: 800px) {
			font: ${({ theme }) => theme.fonts.largeHeading.tiny} !important;
		}
	}
	@media (min-width: 1024px) {
		height: 100%;
		button {
			margin-top: auto;
		}
	}
	@media (min-width: 1220px) {
		padding: 32px 42px 54px 42px;
		border-radius: 30px;
		height: 100%;
		gap: 64px;
		align-items: start;
		> div {
			max-width: 550px;
		}
	}
`;

export const ScInviteEmployeeWrapper = styled.div`
	background: ${({ theme }) => theme.colors.marmelade};
	border-radius: 10px;
	padding: 24px 15px 50px 15px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 24px;
	img {
		width: 225px;
		height: auto;
	}
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 50px 20px 60px 60px;
		display: grid;
		grid-template-columns: 23fr 18fr;
		gap: 104px;
		align-items: end;
		img {
			width: 100%;
			height: auto;
			margin: auto 0;
		}
	}
	@media (min-width: 1440px) {
		border-radius: 30px;
		padding: 50px 20px 60px 60px;
		display: grid;
		grid-template-columns: 23fr 18fr;
		gap: 104px;
		img {
			width: 100%;
			height: auto;
			margin: 0;
		}
	}
	@media (min-width: 1920px) {
		grid-template-columns: 23fr 18fr;
		img {
			margin: 0;
		}
		gap: 450px;
	}
`;

export const ScInfoSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	margin: 50px auto;
	max-width: 875px;
	@media (min-width: 1220px) {
		gap: 42px;
		margin: 120px auto;
	}
	@media (min-width: 1920px) {
		margin: 200px auto;
	}
`;

export const ScInfoButtons = styled(motion.div)`
	display: flex;
	flex-direction: column;
	align-items: center;
	align-items: center;
	gap: 8px;
	margin-top: 10px;
	@media (min-width: 1220px) {
		gap: 24px;
	}
`;

export const ScHowItWorksItemsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 8px;
	> div {
		height: 100%;
	}
	@media (min-width: 1220px) {
		gap: 20px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin-bottom: 20px;
		p,
		h6 {
			max-width: 430px;
			margin: 0 auto;
		}
	}
`;

export const ScBikesShopsHeroWrapper = styled(motion.section)`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	margin: 20px 0 15px 0;
	img {
		width: 245px;
		height: auto;
	}
	@media (min-width: 1220px) {
		img {
			width: 445px;
			height: 390px;
		}
	}
	@media (min-width: 1440px) {
		margin: 50px 0 50px 0;
		gap: 80px;
	}
	@media (min-width: 1920px) {
		margin: 50px 0 140px 0;
		gap: 80px;
		img {
			width: 600px;
			height: 530px;
		}
	}
`;

export const ScBikesShopsHeroTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	max-width: 1000px;
	b {
		margin-top: 5px;
	}
	@media (min-width: 1220px) {
		gap: 60px;
		b {
			margin-top: 50px;
		}
	}
	@media (min-width: 1920px) {
		max-width: 1005px;
	}
`;

export const ScCalculatorAccordionWrapper = styled(motion.div)`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 8px;
	@media (min-width: 1220px) {
		gap: 20px;
		margin-top: 30px;
	}
	@media (min-width: 1440px) {
		margin-top: 50px;
	}
	@media (min-width: 1920px) {
		margin-top: 70px;
	}
`;

export const ScBikesCalculatorSection = styled(motion.section)`
	width: 100%;
`;
