import React from 'react';
import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { Hero } from '../sections/GogetaCycleToWorkScheme/Hero';
import { SchemeInfo } from '../sections/GogetaCycleToWorkScheme/SchemeInfo';
import { Benefits } from '../sections/GogetaCycleToWorkScheme/Benefits';
import { BikeCalculatorSection } from '../sections/GogetaCycleToWorkScheme/BikesCalculatorSection';

const GogetaCycleToWorkScheme = () => {
	return (
		<MainLayout
			title="Gogeta Cycle to Work Scheme | Fair & Flexible Savings"
			description="Discover Gogeta's fair Cycle to Work Scheme offering great savings and flexibility for employees, effortless management for employers, and a better deal for retailers. Learn more today!"
		>
			<ScPage>
				<Hero />
				<SchemeInfo />
				<Benefits />
				<BikeCalculatorSection />
			</ScPage>
		</MainLayout>
	);
};

export default GogetaCycleToWorkScheme;
